.inputFilled {
  flex: 0 0 48px;
}
.inputFilled label {
  top: -2px;
}
.inputFilled label.Mui-focused {
  color: #acb5c8;
}
.inputFilled.required label .requiredStar::after {
  padding-left: 5px;
  color: #F68922;
  content: '*';
}
.inputFilled .MuiInputLabel-outlined:not(.Mui-focused) {
  color: #acb5c8;
}
.inputFilled .MuiOutlinedInput-root > fieldset {
  border-color: #e4e9f2;
}
.inputFilled .MuiOutlinedInput-root > input::placeholder {
  color: #acb5c8;
  opacity: 0.7;
}
.inputFilled .MuiOutlinedInput-root.Mui-focused > fieldset {
  border-color: #e4e9f2;
}
.inputFilled .MuiOutlinedInput-root.Mui-disabled {
  background-color: #e4e9f2;
}
.inputFilled .MuiOutlinedInput-root.Mui-disabled > fieldset {
  background-color: none;
  border: unset;
}
.inputFilled .MuiOutlinedInput-root:hover fieldset {
  border-color: #e4e9f2;
}
.inputFilled .MuiOutlinedInput-root {
  height: calc(48px - 8px);
  border-radius: 4px;
  height: 48px;
  background-color: #f7f9fc;
  color: #2C2F3A;
}
.inputFilled fieldset.Mui-focused {
  border-color: inherit;
}
.inputFilled .helperText {
  color: inherit;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: -0.6rem;
  right: 0px;
  text-align: right;
  margin-right: 14px;
  padding: 0px 3px;
  background-color: white;
}
.inputFilled.hasError .MuiOutlinedInput-root.Mui-focused > fieldset {
  border-color: #e4e9f2;
}
.inputFilled.hasError .MuiOutlinedInput-root fieldset {
  border-color: #cc0000;
}
.inputFilled.hasError .helperText {
  color: #cc0000;
}
form {
  flex: 1 0 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
form .inputFilled {
  margin-top: 13px;
}
.stepper {
  padding: 0px 45%;
  flex: 1;
}
.contentForm {
  display: flex;
  flex-direction: column;
}
.contentForm > div {
  flex: 1;
}
#onboarding .checkBlock {
  padding-top: 8px;
  width: 90%;
}
#onboarding .checkBlock li {
  color: #909299;
  display: flex;
  justify-content: center;
}
#agreeTerms-open-button {
  color: #F68922;
}
