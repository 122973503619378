.checkBlock {
  margin: unset;
  transform: scale(1);
}
.checkBlock > * {
  position: relative;
}
.checkBlock > fieldset {
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 0px;
  margin: 0px;
  position: absolute;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  top: -4px;
  left: -4px;
}
.checkBlock > fieldset legend {
  display: none;
}
.checkBlock li {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.checkBlock.error > fieldset {
  border-color: red;
  border-radius: 4px;
  transform: scale(1);
  font-size: 0.75rem;
}
.checkBlock.error > fieldset legend {
  display: block;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: -0.6rem;
  right: 0px;
  text-align: right;
  margin-right: 14px;
  padding: 0px 3px;
  background-color: white;
  color: red;
}
