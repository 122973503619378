.greenMainStyle {
  background-color: #81b31e;
  color: #81b31e;
  border-color: #81b31e;
}
.stepBasic {
  display: flex;
  align-items: center;
}
.stepBasic .circle {
  border-radius: 50%;
  width: 9px;
  height: 9px;
  background-color: #e4e9f2;
  border: 1px solid #e4e9f2;
  background-color: #f7f9fc;
  transition: all 0.2s ease;
  transition-delay: 0.1s;
}
.stepBasic.greenMain.active .circle {
  background-color: #81b31e;
  color: #81b31e;
  border-color: #81b31e;
  transition-delay: 0.2s;
  flex-shrink: 0;
}
.stepper {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
li.step {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
li.step .circle {
  border-radius: 50%;
  width: 9px;
  height: 9px;
  background-color: #e4e9f2;
  border: 1px solid #e4e9f2;
  background-color: #f7f9fc;
  transition: all 0.2s ease;
  transition-delay: 0.1s;
}
li.step.greenMain.active .circle {
  background-color: #81b31e;
  color: #81b31e;
  border-color: #81b31e;
  transition-delay: 0.2s;
  flex-shrink: 0;
}
li.step > .line {
  content: '';
  height: 0.0625rem;
  width: 100%;
  transition: all 0.2s ease-in-out;
}
li.step.greenMain > .line {
  background: linear-gradient(to right, #81b31e, 50%, #e4e9f2 50%);
  background-size: 200% 200%;
  background-position: right bottom;
}
li.step.active > .line {
  background-position: left bottom;
}
li.stepFirst {
  display: flex;
  align-items: center;
}
li.stepFirst .circle {
  border-radius: 50%;
  width: 9px;
  height: 9px;
  background-color: #e4e9f2;
  border: 1px solid #e4e9f2;
  background-color: #f7f9fc;
  transition: all 0.2s ease;
  transition-delay: 0.1s;
}
li.stepFirst.greenMain.active .circle {
  background-color: #81b31e;
  color: #81b31e;
  border-color: #81b31e;
  transition-delay: 0.2s;
  flex-shrink: 0;
}
