#user-select {
  display: flex;
  align-items: center;
  user-select: none;
  z-index: 1100;
}
.userSelectList {
  background-color: unset;
  font-size: 1.25rem;
  color: white;
}
.triangleIcon {
  position: absolute;
  right: -24px;
}
