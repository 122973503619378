#login {
  flex: 1;
  height: calc(100vh - 48px - var(--safe-area-inset-bottom));
  display: flex;
  flex-direction: column;
  width: 100%;
}
#login .subTitle {
  margin-top: calc(8px * 3);
}
#login .bottomLink {
  color: #909299;
}
#login form {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.agreementPaper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: calc(100% - var(--safe-area-inset-bottom));
  padding: 8px calc(24px * 1.5);
  padding-bottom: var(--safe-area-inset-bottom);
}
.agreementPaper .agreementTitle {
  color: #47525E;
}
.agreementPaper .agreementContent {
  border: 1px #81b31e solid;
  flex-grow: 0;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex-grow: 1;
  -webkit-overflow-scrolling: touch;
}
.agreementPaper .agreementContent .agreementContentText {
  font-size: 0.9rem;
  padding: 0px calc(24px / 2) 24px;
  color: #5A6978;
  text-align: left;
}
.agreementPaper .agreementContent .aggrementCheck {
  position: absolute;
  bottom: 0px;
}
.agreementPaper .agreementContent .aggrementCheck h6 {
  font-weight: normal;
}
.agreementPaper .agreementContent .aggrementCheck .box {
  color: #81b31e;
}
.agreementPaper .stepper {
  background-color: unset;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  max-width: 600px;
}
.agreementPaper .stepper button:first-child {
  padding-left: 0px;
}
.agreementPaper .stepper button:last-child {
  padding-right: 0px;
}
.agreementPaper .agreementAction {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  margin: 0px 0px;
  width: 100%;
  padding-left: calc(24px / 2);
  padding-right: calc(24px / 2);
  max-width: 600px;
}
