.sideDrawer {
  width: 250px;
  padding-left: 24px;
}
.sideDrawer .drawerHeader {
  background-color: #81b31e;
  color: white;
}
.sideDrawer .drawerButton {
  display: flex;
  justify-content: flex-start;
  opacity: 0.7;
}
.sideDrawer .drawerButton svg {
  margin-right: 24px;
  opacity: 0.8;
}
.sideDrawer .drawerButton span {
  flex: 1;
  text-align: left;
}
.switcher {
  padding: 0 16px;
}
.switcher .fieldset {
  display: inline-block;
  position: relative;
  text-align: center;
  padding: 2px;
  border-radius: 50em;
  background-color: rgba(84, 92, 119, 0.1);
}
.switcher input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.switcher label {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 55px;
  height: 31px;
  line-height: 32px;
  cursor: pointer;
  font-size: 12px;
  color: #000;
  opacity: 0.6;
}
.switcher .switch {
  position: absolute;
  top: 2px;
  left: 2px;
  height: 31px;
  width: 55px;
  background-color: #fff;
  border-radius: 50em;
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  transition: transform 0.5s;
}
.switcher input[type="radio"]:checked + label + .switch,
.switcher input[type="radio"]:checked + label:nth-of-type(n) + .switch {
  -webkit-transform: translateX(55px);
  -moz-transform: translateX(55px);
  -ms-transform: translateX(55px);
  -o-transform: translateX(55px);
  transform: translateX(55px);
}
