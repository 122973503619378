body {
  margin: 0;
  font-family: "Roboto", -apple-system, "Helvetica Neue", "Droid Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


:root {
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-top: 0px;
}

@supports (padding-bottom: constant(safe-area-inset-bottom))
  and (padding-bottom: constant(safe-area-inset-top)) { /*iOS < 11.2*/
  :root {
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-top: constant(safe-area-inset-top);
  }
}

@supports (padding-bottom: env(safe-area-inset-bottom))
  and (padding-bottom: env(safe-area-inset-top)) {  /*iOS >= 11.2*/
  :root {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-top: env(safe-area-inset-top);
  }
}
