html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}
li {
  list-style: none;
}
ul {
  padding-inline-start: unset;
}
a {
  color: inherit;
  text-decoration: inherit;
}
#tsaitung-content {
  min-height: calc(100vh - var(--safe-area-inset-bottom));
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header {
  font-size: 28pt;
  height: calc(48px + var(--safe-area-inset-top));
  align-items: center;
  padding: var(--safe-area-inset-top) 24px 0px 24px;
  display: flex;
  justify-content: space-between;
}
.headerBeneath {
  font-size: 28pt;
  height: calc(48px + var(--safe-area-inset-top));
  align-items: center;
  padding: var(--safe-area-inset-top) 24px 0px 24px;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  opacity: 0;
}
.headerNarrowLeft {
  padding-left: calc(24px / 2);
}
.headerNarrowRight {
  padding-right: calc(24px / 2);
}
.bottomBar {
  padding-bottom: var(--safe-area-inset-bottom);
}
.hidden {
  display: none;
}
.invisible {
  visibility: hidden;
}
.topGutter {
  height: calc((100vw - 2 * 24px) * 30 / 100);
  max-height: calc(600px/3);
}
.styledList li:before {
  content: "\203b";
}
.bold {
  font-weight: bolder;
}
