@media screen and (min-width: 993px) and (max-width: 1200px) {
  div.landing {
    justify-content: center;
  }
  div.landing .landingContent {
    border-radius: 40px;
  }
}
.landing {
  padding-top: 100px;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url("/landing.svg");
  background-size: auto 100vh;
  background-color: #81b31e;
  background-repeat: no-repeat;
  background-position: top center;
}
.landingGhostTitle {
  flex: 0 0 20vh;
  opacity: 0;
}
.landingContent {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1 0;
  background-color: white;
  padding-top: 40px;
  padding-bottom: calc(8px + var(--safe-area-inset-bottom) / 2);
  padding-right: 24px;
  padding-left: 24px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  max-height: 460px;
  padding-top: 20px;
}
.landingContent button.nextButton {
  margin-top: calc(10px / 2);
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 24px;
  width: unset;
  border: none;
  color: white;
  height: 48px;
}
.landingContent .boldTitle {
  font-weight: 500;
}
.landingContent form > .loginFills {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.landingContent form > .loginFills .inputFilled {
  margin-top: calc(8px * 2);
}
.landingContent form {
  display: flex;
  justify-content: space-between;
}
.landingContent form > .loginBottom {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.landingContent p.bottomLink {
  margin-top: 8px;
}
